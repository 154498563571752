import styled from 'styled-components';

export const CityPowerAppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #F2F2F2;

  #portalNav {
    margin-bottom: 16px;
  }

  #App {
    width: 100%;
    height: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center
    align-items: center;
    max-height: 100%;
    overflow-y: scroll;
  }

  #AppContainer {
    padding: 0px;
    width: 100%;
    height: 100%;

    .slide-container {
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
    }

    #formCard {
      border: 1px solid #EBEBEB !important;
      width: 100%;

      @media screen and (min-width: 1200px) {
        width: 1000px;
      }

      .card-body {
        padding: 16px 16px 0 16px;

        @media screen and (min-width: 600px) {
          padding: 16px 48px 0 48px;
        }
        
        #employeeCol {
          margin-bottom: 8px;
          justify-content: start;

          @media screen and (min-width: 600px) {
            margin-bottom: 0px;
            justify-content: center;
          }
        }

        #combo-box {
          padding: 0px;
          height: 100%;
          border: none;

          :hover {
            border: none;
          }

          :focus {
            border: none;
          }

          :active {
            border: none;
          }
        }

        .largeLabel {
          font-weight: 600;
          text-align: left;
        }

        .form-check-label {
          text-align: left;
        }

        .btn-outline-primary {
          background-color: #ffffff;
          border: 1px solid #f7ae00;
          color: #f7ae00;
          transition: color 0.3s, background-color 0.3s, border-color 0.3s;

          :hover {
            background-color: #f7ae00;
            border: 1px solid #f7ae00;
            color: #ffffff;
          }

          :focus {
            background-color: #f7ae00;
            border: 1px solid #f7ae00;
            color: #ffffff;
          }

          :active {
            background-color: #fc8600;
            border: 1px solid #fc8600;
          }
        }

        .form-group {
    
          .MuiFormLabel-root {
            top: -4px;
          }
    
          .MuiFormLabel-root.Mui-focused {
            top: 0px;
          }
        }

        .MuiFormControl-root {
          margin-bottom: 0px;
        }

        #combo-box-label.Mui-focused {
          color: #3f51b5;
        }

        .MuiInputBase-root.Mui-focused  {
          fieldset {
            border-color: #3f51b5;
            border-width: 2px;
          }
        }

        .MuiInputBase-root {
          height: 45px;
          border-radius: 0px;

          :hover {
            fieldset {
              border-color: #3f51b5;
              border-width: 2px;
            }
          }
        }

        input[type=checkbox] {
          accent-color: #f7ae00;
        }

        input[type=text], input[type=password], input[type=tel], input[type=email], input[type=number] {
          height: 45px;
        }

        textarea {
          height: 75px;
        }

        input[type=text], input[type=password], input[type=tel], input[type=email], input[type=number], textarea {
          border-radius: 0px;
          transition: border-color 0.3s;

          :focus {
            border: 2px solid #3f51b5;
            outline: none !important;
            outline-offset: none !important;
            box-shadow: none !important;
          }

          :active {
            border: 2px solid #3f51b5;
            outline: none !important;
            outline-offset: none !important;
            box-shadow: none !important;
          }

          :hover {
            border: 2px solid #3f51b5;
          }
        }

        .form-row {
          margin: 5px;
        }
      }

      .card-header, .card-footer  {
        padding: 16px 24px;
        background-color: #ffffff;
        margin: 0px 24px;
      }
    
      .card-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    
      .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        margin-top: 16px;

        @media screen and (max-width: 599px) {
          margin: 0px 16px;
          padding: 0px;
        }
    
        .MuiLink-root {
          color: #3f51b5;
          font-weight: bold;
        }
      }

      .card-header {
        .login-title {
          font-weight: 500;
          line-height: 18px;
          font-size: 18px;
          padding-left: 16px;
    
          @media screen and (min-width: 800px) {
            line-height: 20px;
            font-size: 20px;
          }
        }
    
        .login-logo {
          margin: 0px;
          height: 50px;
          width: max-content;
          padding-right: 16px;
          border-right: 2px solid #000000;
    
          @media screen and (min-width: 800px) {
            height: 60px;
          }     
        }
      }

      .button-group {
        width: 100%;
        padding 16px 0px ;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
        .form-group {
          margin: 8px 0px;
          width: 100%;
    
          button {
            width: 100%;
            height: 100%;
            padding: 8px 0px;
            border-radius: 0px;
            font-weight: bold;
            overflow: hidden;
            position: relative;
            transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    
            :focus {
              outline: none !important;
              outline-offset: none !important;
              box-shadow: none !important;
            }
            
            :active {
              outline: none !important;
              outline-offset: none !important;
              box-shadow: none !important;
            }
          }
        }
    
        .primary {
          background-color: #f7ae00;
          border: 1px solid #f7ae00;
  
          :hover {
            background-color: #fc8600;
            border: 1px solid #fc8600;
          }
  
          :focus {
            background-color: #fc8600;
            border: 1px solid #fc8600;
          }
  
          :active {
            background-color: #fc8600;
            border: 1px solid #fc8600;
          }
        }
    
        .secondary {
          background-color: #ffffff;
          color: #888888;
          border: 1px solid #888888;
    
          :hover {
            background-color: #EBEBEB;
            color: #888888;
          }
  
          :focus {
            background-color: #EBEBEB;
            color: #888888;
          }
  
          :active {
            background-color: #EBEBEB;
            color: #888888;
          }
        }

        #submit-spinner {
          width: 70% !important;
          margin-left: 8px;
        }

        a {
          width: 30%;
          margin-right: 8px;
        }
      }
    }

    #homePageContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .slide-container {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
      }

      .container {
        background-color: #ffffff;
        border: 1px solid #EBEBEB !important;
        padding: 24px;
        margin: 0px;
        max-height: 100%;
        overflow-y: scroll;

        #title-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-bottom: 16px;
          border-bottom: 1px solid #EBEBEB;
        }

        .login-title {
          font-weight: 500;
          line-height: 20px;
          font-size: 20px;
          padding-left: 16px;
    
          @media screen and (min-width: 800px) {
            line-height: 25px;
            font-size: 25px;
          }
        }
    
        .login-logo {
          margin: 16px 0px;
          height: 50px;
          width: max-content;
          padding-right: 16px;
          border-right: 2px solid #000000;
    
          @media screen and (min-width: 800px) {
            height: 75px;
          }     
        }
        .iconRow {
          width: 100%;
          margin: 0px;
        }

        .iconGroup {

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 187px;
            height:187px;
          }

          #image-container {
            width: fit-content;
            height: fit-content;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            margin: 16px;

            opacity: 0.8;
            transition: opacity 0.2s, box-shadow 0.2s, width 0.2s, height 0.2s;

            .icons {
              transition: width 0.2s, height 0.2s;
            }

            :hover {
              opacity: 1;
              box-shadow: 0px 0px 50px #f7ae0080;

              .icons {
                width: clamp(155px, 0px, 0px) !important;
                height: clamp(155px, 0px, 0px) !important;
              }
            }
          }
        }
      }
    }
  }

  #portalNav {
    width: 100%;
  }

  #LoginForm {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    #loginAlertContainer {
      width: 100%;
      min-height: 130px;

      @media screen and (min-width: 400px) {
        min-height: 110px;
      }

      @media screen and (min-width: 450px) {
        min-height: 90px;
      }

      @media screen and (min-width: 650px) {
        min-height: 70px;
      }

      @media screen and (min-width: 1100px) {
        min-height: 50px;
      }

      #loginAlert {
        width: 100%;
        margin: 0px;
      }
    }
  }
}
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-weight: 650;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #000000;

  .link {
    :hover {
      text-decoration: underline;
    }
  }
`;

import React from 'react';
import { logout as apiLogout } from './apiCalls';

export const Context = React.createContext();

export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.zeroState();
  }

  zeroState = () => ({
    portalEndpoint: null,
    apiEndpoint: null,
  });

  setPortalEndpoint = (portalEndpoint) => this.setState({ portalEndpoint });

  setApiEndpoint = (apiEndpoint) => this.setState({ apiEndpoint });

  render() {
    const {
      props: { children },
      state: {
        portalEndpoint,
        apiEndpoint,
      },
      setPortalEndpoint,
      setApiEndpoint,
    } = this;

    console.log('root context state:', this.state);

    return (
      <Context.Provider
        value={{
          portalEndpoint,
          apiEndpoint,
          setPortalEndpoint,
          setApiEndpoint,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

export const checkCookies = (cookies, setState) => {
  console.log('cookies:', cookies);
  const cookiesList = Object.keys(cookies.getAll());
  console.log('cookies list:', cookiesList);
  const authenticated = cookiesList.includes('SESSION');
  const username = cookies.get('username');
  let toSetState = { authenticated };
  if (username != null && username !== '') { toSetState = { ...toSetState, username }; }
  setState(toSetState);
  setTimeout(() => console.log('cookies after waiting 3s:', cookiesList), 3000);
};

export const clearCookies = (cookies) => {
  ['JSESSIONID', 'SESSION', 'subscriberId', 'username'].forEach((cookie) => {
    console.log('removing', cookie, cookies.get(cookie));
    cookies.remove(cookie);
  });
};

export const setAuth = async (
  sessionId,
  username,
  subscriberId,
  cookies,
  setState,
) => {
  await cookies.set('SESSION', sessionId, { path: `/${subscriberId}` });
  cookies.set('username', username, { path: `/${subscriberId}` });
  console.log('cookies list:', cookies.getAll());
  setState({ username, authenticated: true });
};

export const logout = async (setState, cookies, zeroState) => {
  apiLogout();
  setState(zeroState());
  clearCookies(cookies);
  const {
    cookies: { SESSION },
  } = cookies;
  console.log('SESSION cookie now:', SESSION);
  if (cookies.SESSION != null) {
    alert(
      `An issue with cookies occurred.${
        window.location.origin === 'http://localhost:3000'
          ? ' The session cookie is still there. This often happens on localhost.'
          : ''
      }`,
    );
  }
  setState({ authenticated: false });
};

import React, { useState, useEffect } from 'react';
import { Table } from '../../components/tables';
import {
  notificationsTableColumns,
} from '../../constants/citypower/constants';
import {
  getNotificationsTable,
  getColumns,
} from '../../scripts/citypower/tablesScripts';

export const Notifications = () => {
  const [rows, setRows] = useState(null);
  document.title = 'Notifications | City Power Portal';
  const { headers, headerNames, widths } = notificationsTableColumns;
  const columns = getColumns(headers, headerNames, widths);
  const [loading, setLoading] = useState(true);

  const setRowsAsync = async () => {
    setRows(await getNotificationsTable(notificationsTableColumns));
    setLoading(false);
  };

  useEffect(() => {
    // DEV (TODO: delete) >>>
    // if (window.location.origin === "http://localhost:3000") {
    //   console.log("You are on localhost and as such, test data will be used");
    //   setRows(
    //     convertRowsForTable(testNotificationsTable, notificationsTableColumns)
    //   );
    // } else {
    // <<<
    setRowsAsync();
    // } //DEV (TODO: delete)
  }, []);
  console.log('rows:', rows);

  return <Table title="Notifications" {...{ columns, rows, loading }} />;
};

import styled from 'styled-components';

export const LoginFormContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 800px) {
    width: 700px;
  }

  .slide-container {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  #loginFormCard {
    border: 1px solid #EBEBEB !important;
    width: 100%;

    .card-header, .card-footer  {
      padding: 16px 20px;
      background-color: #ffffff;
      margin: 0px 24px;
    }
  
    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .card-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
  
      .MuiLink-root {
        color: #3f51b5;
        font-weight: bold;
      }
    }

    .card-header {
      .login-title {
        font-weight: 500;
        line-height: 20px;
        font-size: 20px;
        padding-left: 16px;
  
        @media screen and (min-width: 800px) {
          line-height: 25px;
          font-size: 25px;
        }
      }
  
      .login-logo {
        margin: 16px 0px;
        height: 50px;
        width: max-content;
        padding-right: 16px;
        border-right: 2px solid #000000;
  
        @media screen and (min-width: 800px) {
          height: 75px;
        }     
      }
    }
  }

  .card-body {
    padding: 24px;

    .form-group {
      margin: 16px 0px;

      label {
        top: -4px;
      }

      label.Mui-focused {
        top: 0px;
      }
      
      input {
        padding: 12px 16px;
        height: 21px;
      }
    }
    
    .MuiInputBase-root {
      height: 45px;
      border-radius: 0px;

      :hover {
        fieldset {
          border-color: #3f51b5;
          border-width: 2px;
        }
      }
    }
  }

  .button-group {
    padding 16px 0px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-group {
      margin: 8px 0px;
      width: 100% !important;

      button {
        width: 100%;
        padding: 8px 0px;
        border-radius: 0px;
        font-weight: bold;
        overflow: hidden;
        position: relative;
        transition: color 0.3s, background-color 0.3s, border-color 0.3s;

        :focus {
          outline: none !important;
          outline-offset: none !important;
          box-shadow: none !important;
        }
        
        :active {
          outline: none !important;
          outline-offset: none !important;
          box-shadow: none !important;
        }
      }
    }

    .primary {
      background-color: #f7ae00;
      border: 1px solid #f7ae00;

        :hover {
          background-color: #fc8600;
          border: 1px solid #fc8600;
        }

        :focus {
          background-color: #fc8600;
          border: 1px solid #fc8600;
        }

        :active {
          background-color: #fc8600;
          border: 1px solid #fc8600;
        }
    }

    .secondary {
      background-color: #ffffff;
      color: #888888;
      border: 1px solid #888888;

        :hover {
          background-color: #EBEBEB;
          color: #888888;
        }

        :focus {
          background-color: #EBEBEB;
          color: #888888;
        }

        :active {
          background-color: #EBEBEB;
          color: #888888;
        }
    }

    #submit-spinner {
      width: 100% !important;
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;

      .form-group {
        margin: 0px 8px;
        width: fit-content;
      }
    }
  }
`;

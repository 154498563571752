import { apiUrl, apiUrlWithHttps } from '../constants/constantsGlobal';

const headers = {
  // Accept: "application/json, text/javascript, q=0.01",
  'Content-Type': 'application/problem+json',
};

export const fetchApi = (endpoint, method, rest) => {
  console.log('endpoint', endpoint);
  return fetch(`https://${endpoint}`, {
    method,
    headers,
    credentials: 'include',
    ...rest,
  });
};

export const getUserRoles = async () => fetchApi(`${apiUrl}usermanager/getUserRoles`, 'GET', {
  languageCode: 'en',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const standardError = (
  e,
  errorOpening = 'Error making this request',
) => {
  console.error(e);
  let errMsg = e.responseText || e.message;
  if (!errMsg || errMsg.length === 0) { errMsg = 'unknown error, you are possibly not connected.'; }
  alert(`${errorOpening}: ${errMsg}`);
  return false;
};

export const byClassAndProp = async (className, propertyName) => fetchApi(
  `${apiUrl}customerportalmanager/findRootItemsByClassAndProperty?className=${className}&propertyName=${propertyName}&currentItemId=0&subscriberId=cp_mdt&queryString=`,
  'GET',
  { credentials: 'include' },
);

export const getAllLabels = async () => fetchApi(`${apiUrl}subscribercustomlabelsmanager/getAllLabelsREST`, 'GET', {
  languageCode: 'en',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const isAuthenticated = async () => fetchApi(`${apiUrl}usermanager/isSessionAuthenticated`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => {
    console.error('Comms error:', e);
    return false;
  });

export const logout = async () => fetchApi(`${apiUrl}sessionutilsmanager/logout`, 'GET', {
  credentials: 'include',
}).catch((e) => {
  console.error('Comms error:', e);
  return false;
});

export const getPortalCustomerDetails = () => fetchApi(`${apiUrl}customerportalmanager/getPortalCustomerDetails`, 'GET', {
  async: true,
  credentials: 'include',
});

export const updatePortalCustomerDetails = (customerDetailsObject) => fetchApi(`${apiUrl}customermanager/updatePortalCustomerDetails`, 'POST', {
  body: JSON.stringify(customerDetailsObject),
});

export const getProfileCustomerTypes = async () => byClassAndProp('Customer', 'customerType')
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getWorkRequestTypes = () => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByClassAndProperty?className=WorkOrderRequest&propertyName=workRequestType`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequestSubtypes = (parentCode) => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByParentCodeClassProperty?className=WorkOrderRequest&propertyName=workRequestType&code=${parentCode}`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequest = (code) => fetchApi(
  `${apiUrl}customerportalmanager/getWorkRequest${
    code ? `?code=${code}` : ''
  }`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequestStatus = (id) => fetchApi(`${apiUrl}workorderrequestmanager/getStatusDWR?id=${id}`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getWorkRequestNextStatuses = (statusId, objectId) => fetchApi(
  `${apiUrl}statusreferencemanager/getNextStatusesDWR?className=WorkOrderRequest&statusId=${statusId}&objectId=${objectId}`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getCustomLookup1Values = () => fetchApi(
  `${apiUrl}custommanager_servest_is/getWRcustomLookup1Values`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getCustomLookup1ValuesBedworth = () => fetchApi(
  `${apiUrl}custommanager_bedworth/getWRcustomLookup1Values`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

// This consumes form data to add an image
// @FormDataParam("file") InputStream uploadedInputStream,
// @FormDataParam("file") FormDataContentDisposition fileDetail,
// @FormDataParam("workRequestId") long workRequestId)
export const addWorkRequestAttachment = (formData, subscriberId) => fetch(`${apiUrlWithHttps}smartcitymanager/addWorkRequestAttachment`, {
  method: 'POST',
  async: false,
  credentials: 'include',
  body: formData,
  processData: false,
  contentType: false,
  headers: {
    credentials: 'include',
    subscriberId,
  },
})
  .then((res) => res.json())
  .catch((e) => {
    console.error('Comms error:', e);
    return [];
  });

export const imageUrl = (id, subscriberId) => `${apiUrl}modal/viewimagefile.html?id=${id}&subscriberId=${subscriberId}`;

export const postWorkRequest = (workRequestObject) => fetch(`${apiUrlWithHttps}customerportalmanager/postWorkRequest`, {
  method: 'POST',
  async: true,
  body: JSON.stringify(workRequestObject),
  credentials: 'include',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
})
  .then((res) => res)
  .catch((e) => e);

export const getWorkRequestNoteTypes = () => fetchApi(`${apiUrl}referencelistmanager/findAvailableNoteTypesDWR`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const addNoteToWorkRequest = (id, noteTypeId, text) => fetchApi(`${apiUrl}workorderrequestmanager/addNote?id=${id}&noteTypeId=${noteTypeId}&text=${text}`, 'GET', {
  credentials: 'include',
})
  .then()
  .catch((e) => console.error(e));

import React, { createElement, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './styles/App.scss';
import './styles/login.scss';
import './styles/global.scss';
import './styles/LandingPage.scss';
import {
  Context as RootContext,
  ContextManager as RootContextManager,
} from './functions/context';
import { ContextManager as CityPowerContextManager } from './functions/citypower/context';
import { CityPowerApp } from './CityPowerApp';

export const endpointsPortalAndApi = [
  ['/cp_mdt', 'za4', 'City Power Web Fault Logging', 'electricity'],
];

const RootAppRouter = () => {
  useContext(RootContext);
  return (
    <>
      <Route path="/">
        {createElement(CityPowerContextManager, null, createElement(CityPowerApp))}
      </Route>
    </>
  );
};

const RootApp = () => (
  <React.StrictMode>
    <RootContextManager>
      <BrowserRouter>
        <Switch>
          <RootAppRouter />
        </Switch>
      </BrowserRouter>
    </RootContextManager>
  </React.StrictMode>
);

ReactDOM.render(<RootApp />, document.getElementById('root'));

import { apiUrl } from '../constants/constantsGlobal';
import { fetchApi, standardError } from '../functions/apiCalls';

/**
 * Get Map defaults: mapZoom, centerLat, centerLng
 */
export const getWorkMapInfo = async () => fetchApi(`${apiUrl}spatiallayermanager/getWorkMapInfo?subscriberId=cp_mdt`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => {
    standardError(e, 'Error occurred');
  });

export const getQueryLayerPoints = async (layerId, forceReload, mapTypeCode, pMinLat, pMinLong, pMaxLat, pMaxLong) => fetchApi(`${apiUrl}spatiallayermanager/getQueryLayerPoints?mapTypeCode=${mapTypeCode}&pMinLat=${pMinLat}&pMinLong=${pMinLong}&pMaxLat=${pMaxLat}&pMaxLong=${pMaxLong}&layerId=${layerId}&forceReload=${forceReload}`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => {
    standardError(e, 'Error occured');
  });

import React from 'react';
import {
  Button, Form, Row, Col, Card, Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import * as Material from '@material-ui/core';
import { AddressInput } from '../../components/AddressInput';
import {
  register,
  getProfile,
  updateProfile,
} from '../../scripts/citypower/profileScripts';
import { handleLogging, handleOTP } from '../../functions/forms';
import { Context } from '../../functions/citypower/context';
import { getProfileCustomerTypes } from '../../functions/apiCalls';
import { OTPModal } from '../../components/OTPModal';
import Ripple from '../../components/citypower/ripple';
import SlideIn from '../../components/citypower/slidein';

export class ProfileForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const {
      newProfile,
    } = this.props;
    /**
     * requiredMissing: null = the input is not required (or not displayed yet)
     * false = the input is required and correctly filled in upon submission
     * true = the input is required and not filled in upon submission
     */
    this.state = {
      entries: this.initEntriesWithMap(),
      requiredMissing: this.initRequiredMissingWithMap(),
      customerTypesList: [],
      isEmployee: false,
      showModal: false,
      submitting: false,
    };
    this.newProfile = newProfile;
  }

  /**
   * INIT VALUES >>
   */
  initEntries = () => ({
    name: '',
    profileCustomerType: '',
    accountNumber: '',
    meterNumber: '',
    password: '',
    passwordHint: '',
    employeeSAPNumber: '',
    contactPerson: '',
    mobileNumber: '',
    homeNumber: '',
    emailAddress: '',
    optOutPreferences: [],
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    description: '',
  });

  initRequiredMissing = () => ({
    name: false,
    profileCustomerType: false,
    password: false,
    passwordHint: false,
    contactPerson: false,
    mobileNumber: false,
  });

  initRequiredMissingWithMap = () => ({
    ...this.initRequiredMissing(),
    streetNumber: false,
    streetName: false,
    suburb: false,
  });
  /**
   * << INIT VALUES
   */

  componentDidMount = async () => {
    if (!this.newProfile) {
      const entries = await getProfile();
      Object.keys(entries).forEach((entry) => {
        if (entries[entry] == null) {
          entries[entry] = this.initEntries()[entry];
        }
      });
      this.setState({ entries });
    }
    const customerTypesList = (await getProfileCustomerTypes()) || [];
    this.setState({ customerTypesList });
  };

  handleSubmit = async () => {
    const { setAuth } = this.context;
    const { entries } = this.state;
    const handleLoggingResponse = handleLogging(this.state, (s) => this.setState(s));
    if (handleLoggingResponse) {
      console.log('handling submit');
      if (this.newProfile) {
        const registerResponse = await register(
          entries,
          (s) => this.setState(s),
          setAuth,
        );
        console.log('register response:', registerResponse);
        const { result } = registerResponse;
        if (result === 'OTP_REQUIRED' || result === 'OTP_ERROR') { this.setState({ showModal: true }); }
        if (result === 'ALREADY_REGISTERED' || result === 'SUCCESS') { this.setState({ showModal: false }); }
      } else {
        updateProfile(entries, (s) => this.setState(s));
      }
    }
  };

  /**
   * UPDATE VALUES >>
   */
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };
  /**
   * << UPDATE VALUES
   */

  onResultFound = (result) => {
    console.log('result passed to parent:', result);
    const {
      streetNumber, streetName, city, postalCode, suburb,
    } = result;
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || entries.streetNumber || '';
    toUpdate.streetName = streetName || entries.streetName || '';
    toUpdate.city = city || entries.city || '';
    toUpdate.postalCode = postalCode || entries.postalCode || '';
    toUpdate.suburb = suburb || entries.suburb || '';
    this.setState({ entries: toUpdate });
  };

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  render() {
    const { newProfile } = this;
    const { setAuth } = this.context;
    const {
      entries,
      entries: {
        name,
        profileCustomerType,
        accountNumber,
        meterNumber,
        password,
        passwordHint,
        employeeSAPNumber,
        contactPerson,
        mobileNumber,
        homeNumber,
        emailAddress,
        optOutPreferences,
      },
      requiredMissing,
      customerTypesList,
      isEmployee,
      showModal,
      submitting,
    } = this.state;
    if (isEmployee) {
      /**
       * Initialise states for SAP number input when Employee? is checked
       */
      requiredMissing.employeeSAPNumber == null
        && this.initRequiredState('employeeSAPNumber');
    } else {
      /**
       * When not employee, reset states for SAP number
       */
      if (entries.employeeSAPNumber !== '') {
        this.setEntries('employeeSAPNumber', '');
      }
      if (requiredMissing.employeeSAPNumber != null) {
        this.updateRequiredState('employeeSAPNumber', null);
      }
    }

    return (
      <Container fluid id="ProfileForm">
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Card id="formCard" className="text-center">
              <Card.Header>
                <img src="citypower/logo.png" className="login-logo" alt="City Power Logo" />
                <div className="login-title">
                  {newProfile
                    ? 'Account Registration'
                    : 'Edit your Profile'}
                </div>
              </Card.Header>
              <Card.Body id="formBody">
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Customer Account Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* NAME */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('name')}
                          >
                            Name *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="name"
                          type="text"
                          required
                          isInvalid={requiredMissing.name === true}
                          value={name}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* CUSTOMER TYPE */}
                      <Col
                        md={6}
                        style={{
                          display: 'flex',
                          alignItems: 'end',
                        }}
                      >
                        <Material.TextField
                          name="profileCustomerType"
                          select
                          fullWidth
                          margin="normal"
                          label="Customer Type"
                          variant="outlined"
                          required
                          error={requiredMissing.profileCustomerType === true}
                          value={profileCustomerType}
                          onChange={this.updateReqEntries}
                        >
                          <Material.MenuItem
                            key="blank"
                            value=""
                          />
                          {customerTypesList.map((type) => (
                            <Material.MenuItem
                              key={type.value}
                              value={type.value}
                            >
                              {type.label}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* ACCOUNT NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Account Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="accountNumber"
                          value={accountNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                      {/* METER NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Meter Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="meterNumber"
                          value={meterNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* PASSWORD */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('password')}
                          >
                            Password *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="password"
                          name="password"
                          required
                          isInvalid={requiredMissing.password === true}
                          value={password}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* PASSWORD HINT */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('passwordHint')}
                          >
                            Password Hint *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="passwordHint"
                          required
                          isInvalid={requiredMissing.passwordHint === true}
                          value={passwordHint}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ borderBottom: '1px solid #bbbbbb', paddingBottom: '24px', marginBottom: '24px' }}>
                    <Row>
                      {/* CITY POWER EMPLOYEE? */}
                      <Col xs={12} md={6} id="employeeCol">
                        <Form.Check
                          type="checkbox"
                          name="isEmployee"
                          id="isEmployee"
                          label="City Power Employee?"
                          checked={isEmployee}
                          onChange={(e) => this.setState({ isEmployee: e.target.checked })}
                        />
                      </Col>
                      {/* EMPLOYEE SAP NUMBER */}
                      <Col xs={12} md={6} style={{ opacity: isEmployee !== true ? '0.6' : '', pointerEvents: isEmployee !== true ? 'none' : '' }}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('employeeSAPNumber')}
                          >
                            Employee SAP Number *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="employeeSAPNumber"
                          required
                          isInvalid={
                              requiredMissing.employeeSAPNumber === true
                            }
                          value={employeeSAPNumber}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Contact Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CONTACT PERSON */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('contactPerson')}
                          >
                            Contact Person *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="contactPerson"
                          required
                          isInvalid={requiredMissing.contactPerson === true}
                          value={contactPerson}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* MOBILE NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('mobileNumber')}
                          >
                            Mobile Number *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="tel"
                          name="mobileNumber"
                          aria-describedby="helperText"
                          required
                          isInvalid={requiredMissing.mobileNumber === true}
                          value={mobileNumber}
                          onChange={this.updateReqEntries}
                          readOnly={!newProfile}
                        />
                        <Form.Text
                          muted
                          id="helperText"
                          style={{ textAlign: 'left' }}
                        >
                          This will be your username
                        </Form.Text>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ borderBottom: '1px solid #bbbbbb', paddingBottom: '24px', marginBottom: '24px' }}>
                    <Row className="formInputs">
                      {/* HOME NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Home Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="tel"
                          name="homeNumber"
                          value={homeNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                      {/* EMAIL ADDRESS */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Email Address
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="email"
                          name="emailAddress"
                          value={emailAddress}
                          onChange={this.updateEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* SMS OPT-OUT PREFERENCES */}
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      SMS opt-out Preferences (tick to opt out)
                    </Form.Label>
                  </Form.Group>
                  <Form.Group style={{ borderBottom: '1px solid #bbbbbb', paddingBottom: '24px', marginBottom: '24px' }}>
                    {[
                      ['Outage Notifications', 'ON'],
                      ['Other Notifications', 'OTN'],
                      ['Company Announcements', 'CA'],
                      ['Community Empowerment', 'CE'],
                    ].map((value) => (
                      <Form.Row key={value[1]}>
                        <Form.Check
                          type="checkbox"
                          id={`optOut-${value[1]}`}
                          label={value[0]}
                          checked={(optOutPreferences || []).includes(value[1])}
                          onChange={(e) => {
                            const code = value[1];
                            console.log(
                              'code:',
                              code,
                              'checked',
                              e.target.checked,
                            );
                            if (e.target.checked) {
                              this.setEntries(
                                'optOutPreferences',
                                optOutPreferences.concat(code),
                              );
                            } else {
                              this.setEntries(
                                'optOutPreferences',
                                optOutPreferences.filter((p) => p !== code),
                              );
                            }
                          }}
                        />
                      </Form.Row>
                    ))}
                  </Form.Group>

                  <AddressInput
                    entries={entries}
                    updateEntries={this.updateEntries}
                    requiredMissing={requiredMissing}
                    updateReqEntries={this.updateReqEntries}
                    onResultFound={this.onResultFound}
                    page="profile"
                  />
                </Form>
              </Card.Body>
              <Card.Footer>
                <div className="button-group">
                  <Form.Group id="finalButtons">
                    <Link to="/home">
                      <Button className="secondary" type="submit">
                        Back
                        <Ripple />
                      </Button>
                    </Link>
                    <div id="submit-spinner">
                      {!submitting && (
                      <Button
                        className="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        {newProfile && 'Register'}
                        {!newProfile && 'Save and continue'}
                        <Ripple />
                      </Button>
                      )}
                      {submitting && <Spinner />}
                    </div>
                  </Form.Group>
                </div>
              </Card.Footer>
            </Card>
          </SlideIn>
        </div>
        <OTPModal
          onChangeOTP={(OTP) => this.setEntries('otp', OTP)}
          showModal={showModal}
          setShowModal={(s) => this.setState({ showModal: s })}
          setSubmitting={(submit) => this.setState({ submit })}
          mobileNumber={mobileNumber}
          handleOTP={(setOtp, otp) => handleOTP(
            register,
            setAuth,
            entries,
            (s) => this.setState(s),
            setOtp,
            otp,
          )}
        />
      </Container>
    );
  }
}

export const RegistrationForm = () => <ProfileForm newProfile />;

import React from 'react';
import {
  Alert, Navbar, Nav, NavDropdown, Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/citypower/header.scss';
import { LogoHeader } from '../header';

export const CityPowerHeader = () => (
  <LogoHeader
    title="City Power Web Fault Logging"
    imgSrc="citypower/logo.png"
  />
);

export class LoginAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alertShow: true };
  }

  render() {
    const {
      alertShow,
    } = this.state;
    return (
      <Alert
        variant="warning"
        id="loginAlert"
        dismissible
        show={alertShow}
        onClose={() => this.setState({ alertShow: false })}
      >
        ALERT: Please note that this system requires all users to register
        before they can log faults using the &apos;Register&apos; button
        below
      </Alert>
    );
  }
}
export const PortalNav = () => (
  <Navbar bg="warning" variant="dark" expand="md" id="portalNav">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Link to="/home" className="link-item">
          Home
        </Link>
        <NavDropdown title="Log a fault" className="basic-nav-dropdown">
          <Link to="/logFaultMyAddress">
            <div className="dropdown-item">At my address</div>
          </Link>
          <NavDropdown.Divider />
          <Link to="/logFaultOtherAddress">
            <div className="dropdown-item">At another address</div>
          </Link>
        </NavDropdown>
        <NavDropdown title="View faults" className="basic-nav-dropdown">
          <Link to="/openFaults">
            <div className="dropdown-item">Open faults</div>
          </Link>
          <NavDropdown.Divider />
          <Link to="/allFaults">
            <div className="dropdown-item">All faults</div>
          </Link>
        </NavDropdown>
        <Link to="/notifications" className="link-item">
          Notifications
        </Link>
      </Nav>
      <Form inline>
        <Nav className="mr-auto">
          <Link to="/logout" className="link-item">
            Log out
          </Link>
        </Nav>
      </Form>
    </Navbar.Collapse>
  </Navbar>
);

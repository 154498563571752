import { apiUrl } from '../../constants/constantsGlobal';
import { logFaultMapping } from '../../constants/citypower/constants';
import { fetchApi, byClassAndProp } from '../../functions/apiCalls';
import { getData } from '../../functions/forms';

export const logFault = (entries, setState, address) => {
  console.log('entries:', entries);
  // let data = {};
  // for (let entry in entries) {
  //   Object.assign(data, { [logFaultMapping[entry]]: entries[entry] });
  // }
  const data = getData(entries, logFaultMapping);

  console.log('data:', data);
  console.log('data stringified:', JSON.stringify(data));

  fetchApi(`${apiUrl}calltakemanager/logCall${address}`, 'POST', {
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((json) => {
      const { code, result, errorMessage } = json;
      console.log('logfault:', json);
      if (result === 'FAILURE') {
        setState({
          submitting: false,
        });
        alert(`${errorMessage}`);
      } else {
        const entriesBlank = { ...entries };
        for (let i = 0; i < entriesBlank.length; i++) {
          entriesBlank[i] = '';
        }
        setState({
          submitting: false,
          entries: entriesBlank,
        });
        alert(`Fault successfully logged. Reference: ${code}`);
        // navigate to home
      }
    })
    .catch((e) => {
      setState({
        submitting: false,
      });
      console.error(e);
      const errMsg = e.responseText != null && e.responseText !== ''
        ? e.responseText
        : 'unknown error, you are possibly not connected.';
      alert(`Error with logging your fault: ${errMsg}`);
    });
};

export const getCallTypes = async () => byClassAndProp('Call', 'workRequestType')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getCustomerTypes = async () => byClassAndProp('WorkOrderRequest', 'customLookup2')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getSubCallTypes = async (callTypeCode) => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByParentCodeClassProperty?className=WorkOrderRequest&propertyName=workRequestType&code=${callTypeCode}`,
  'GET',
  { credentials: 'include' },
);

// export const getSubCallTypes = (callTypeCode) => {
//   console.log('call type code/value:', callTypeCode);
//   return fetch(
//     `${apiUrl}referencelistmanager/findCodeDescriptionByParentCodeClassProperty?
// className=WorkOrderRequest&propertyName=workRequestType&code=${callTypeCode}`,
//     {
//       method: 'GET',
//       async: false,
//       headers: {
//         'Content-Type': 'application/problem+json',
//       },
//     },
//   )
//     .then((res) => res.json())
//     .then((resJson) => {
//       console.log(resJson);
//       return resJson;
//     })
//     .catch((e) => console.error('Comms error:', e));

export const logFaultMyAddress = (entries, setState) => logFault(entries, setState, 'MyAddress');

export const logFaultOtherAddress = (entries, setState) => logFault(entries, setState, 'OtherAddress');
